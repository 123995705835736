import React, { useContext, useEffect, useState } from "react";
import ImageInput from "../../common/ImageInput";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import getThumbnailUrl from "../../../utils/thumbnails";

const OrganizationForm = ({
  onSave,
  onCancel,
  onDelete,
  organizationToEdit,
}) => {
  const {
    setOrganization: setCurrentOrganization,
    organizationToEdit: currentOrganization,
  } = useContext(OrganizationsContext);

  const [currentThumbnail, setCurrentThumbnail] = useState(
    currentOrganization?.thumbnail || ""
  );

  const handleThumbnailChange = (thumbnail) => {
    if (typeof thumbnail === "string") {
      setCurrentThumbnail(getThumbnailUrl(thumbnail));
    } else {
      setCurrentThumbnail("");
    }
  };

  useEffect(() => {
    setCurrentOrganization(organizationToEdit, true);
    handleThumbnailChange(organizationToEdit?.thumbnail);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentOrganization({ ...currentOrganization, [name]: value }, true);
  };

  const handleImageChange = (value) => {
    setCurrentOrganization(
      { ...currentOrganization, "thumbnail": value },
      true
    );
    handleThumbnailChange(value);
  };

  const handleSave = () => {
    onSave(currentOrganization);
  };

  const renderCancelButton = () =>
    onCancel && (
      <button type="button" className="col-12 col-md-6 btn" onClick={onCancel}>
        Cancel
      </button>
    );

  const renderSaveButton = () =>
    onSave && (
      <button
        type="button"
        className={`col-12 btn btn-primary ${onCancel ? "col-md-6" : ""}`}
        onClick={handleSave}
      >
        Save
      </button>
    );

  const renderDeleteButton = () =>
    onDelete && (
      <div className="d-flex justify-content-end col-12 ps-0 pe-0">
        <button
          type="button"
          className={`col-12 btn btn-outline-danger col-md-3 mb-3`}
          onClick={() => onDelete(currentOrganization, true)}
        >
          <i className="fas fa-trash me-2"></i> Delete
        </button>
      </div>
    );

  return (
    <div className="h-100 d-flex flex-column">
      <div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={currentOrganization?.name || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="tagline" className="form-label">
            Tagline
          </label>
          <input
            className="form-control"
            name="tagline"
            type="text"
            rows="3"
            value={currentOrganization?.tagline || ""}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mb-3 ps-0">
            <label htmlFor="stripe_public" className="form-label">
              Stripe Public
            </label>
            <input
              type="text"
              className="form-control"
              name="stripe_public"
              value={currentOrganization?.stripe_public || ""}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mb-3 pe-0">
            <label htmlFor="stripe_secret" className="form-label">
              Stripe Secret
            </label>
            <input
              type="text"
              className="form-control"
              name="stripe_secret"
              value={currentOrganization?.stripe_secret || ""}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mb-3 px-0">
            <label htmlFor="stripe_webhook_secret" className="form-label">
              Stripe Webhook Secret
            </label>
            <input
              type="text"
              className="form-control"
              name="stripe_webhook_secret"
              value={currentOrganization?.stripe_webhook_secret || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="openai_api_key" className="form-label">
            Open AI API Key
          </label>
          <input
            type="text"
            className="form-control"
            name="openai_api_key"
            value={currentOrganization?.openai_api_key || ""}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="domain" className="form-label">
            Domain
          </label>
          <input
            type="text"
            className="form-control"
            name="domain"
            value={currentOrganization?.domain || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="thumbnail" className="form-label">
            Upload Thumbnail
          </label>
          <div className="d-flex align-items-center">
            {currentThumbnail && (
              <img
                src={currentThumbnail}
                alt={`Thumbnail for Organization: ${organizationToEdit.name}`}
                style={{
                  maxWidth: "25%",
                  objectFit: "cover",
                  marginRight: "1rem",
                }}
              />
            )}
            <ImageInput modifier={(value) => handleImageChange(value)} />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        {renderDeleteButton()}
        {renderCancelButton()}
        {renderSaveButton()}
      </div>
    </div>
  );
};

export default OrganizationForm;
