
import { getArgs } from '../utils';
import api from './api';

const route = '/organizations';

const OrganizationsService = {
  getAdminOrganizations: (filter) => api.get(`${route}/admin/all?query=${filter}`),
  getUserOrganizations: (filter) => api.get(`${route}?query=${filter}`),
  getSingleOrganization: (organization_id) => api.get(`${route}/${organization_id}`),
  getOrganizationUsers: (organization_id, filters) => api.get(`${route}/${organization_id}/users?${getArgs(filters)}`),
  getSingleUserOrganization: (user_id, organization_id) => api.get(`${route}/${organization_id}/users/${user_id}`),
  postUserOrganization: (data) => api.post(`${route}/users`, { ...data }),
  deleteOrganization: (organization_id) => api.delete(`${route}/${organization_id}`),
  deleteUserOrganization: (organization_id, user_id) => api.delete(`${route}/${organization_id}/${user_id}`),
  postOrganization: (organization) => api.post(route, organization, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }),
  putOrganization: (organization) => api.put(route, organization, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }),
};

export default OrganizationsService;