import React, { createContext, useReducer, useContext } from "react";
import OrganizationsService from "../services/OrganizationsService";
import OrganizationsReducer from "../reducers/OrganizationsReducer";
import {
  ORGANIZATIONS_RECEIVED,
  ADMIN_ORGANIZATIONS_RECEIVED,
  SET_INTERCEPTOR,
  SET_ORGANIZATION,
  CREATE_ORGANIZATION,
  SET_PROPERTY_ORGANIZATION,
  SET_USER_ORGANIZATION,
  SET_ORGANIZATION_TO_EDIT,
} from "../types/Organizations";
import { AuthContext } from "./AuthContext";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER, USERS_RECEIVED } from "../types";

const initialState = {
  organizationInterceptor: null,
  adminAllOrganizations: [],
  user_organization: null,
  organizationToEdit: {},
  organizations: null,
  organization: null,
  spinner: false,
  users: null,
};

export const OrganizationsContext = createContext(initialState);

export const OrganizationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrganizationsReducer, initialState);
  const { alert, success, clearModal } = useContext(ModalContext);
  const { recoverPassword } = useContext(AuthContext);

  const getUserOrganizations = (callback, filter = "") => {
    dispatch({ type: SHOW_SPINNER });
    OrganizationsService.getUserOrganizations(filter)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { organizations } = response.data;
        dispatch({ type: ORGANIZATIONS_RECEIVED, payload: organizations });
        if (typeof callback === "function") {
          callback(organizations);
        }
      })
      .catch((error) => dispatch({ type: HIDE_SPINNER }));
  };

  const getAdminOrganizations = (callback, filter = "") => {
    dispatch({ type: SHOW_SPINNER });
    OrganizationsService.getAdminOrganizations(filter)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { organizations } = response.data;
        dispatch({
          type: ADMIN_ORGANIZATIONS_RECEIVED,
          payload: organizations,
        });
        if (typeof callback === "function") {
          callback(organizations);
        }
      })
      .catch((error) => dispatch({ type: HIDE_SPINNER }));
  };

  const getSingleOrganization = (organization_id, filters, editMode) => {
    if (!organization_id) {
      return;
    }
    dispatch({ type: SHOW_SPINNER });
    OrganizationsService.getSingleOrganization(organization_id, filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { organization } = response.data;
        if (!!editMode) {
          dispatch({ type: SET_ORGANIZATION_TO_EDIT, payload: organization });
        } else {
          dispatch({ type: SET_ORGANIZATION, payload: organization });
        }
      })
      .catch((error) => dispatch({ type: HIDE_SPINNER }));
  };

  const getSingleUserOrganization = (user_id, organization_id) => {
    if (user_id && organization_id) {
      OrganizationsService.getSingleUserOrganization(user_id, organization_id)
        .then((response) => {
          dispatch({ type: HIDE_SPINNER });
          const { user } = response.data;
          dispatch({ type: SET_USER_ORGANIZATION, payload: user });
        })
        .catch((error) => dispatch({ type: HIDE_SPINNER }));
    }
  };

  const getOrganizationUsers = (organization_id, filters) => {
    OrganizationsService.getOrganizationUsers(organization_id, filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { users } = response.data;
        dispatch({ type: USERS_RECEIVED, payload: users });
      })
      .catch((error) => dispatch({ type: HIDE_SPINNER }));
  };

  const setInterceptor = (interceptor) => {
    dispatch({ type: SET_INTERCEPTOR, payload: interceptor });
  };

  const setOrganization = (organization, editMode) => {
    if (!!editMode) {
      dispatch({ type: SET_ORGANIZATION_TO_EDIT, payload: organization });
    } else {
      dispatch({ type: SET_ORGANIZATION, payload: organization });
    }
  };

  const createOrganization = () => {
    dispatch({ type: CREATE_ORGANIZATION });
  };

  const setPropertyOrganization = (key, value) => {
    dispatch({ type: SET_PROPERTY_ORGANIZATION, payload: { key, value } });
  };

  const getFormData = (organization) => {
    const formData = new FormData();
    formData.append("file", organization.thumbnail);
    const organizationData = JSON.stringify(organization);
    formData.append("organization", organizationData);
    return formData;
  };

  const saveOrganization = (organization, callback) => {
    if (!organization.name || organization.name === "") {
      alert("Please add a name to your organization");
      return;
    }

    dispatch({ type: SHOW_SPINNER });

    const organizationFormData = getFormData(organization);
    let service = OrganizationsService.putOrganization;
    if (isNaN(parseInt(organization.organization_id))) {
      service = OrganizationsService.postOrganization;
    }
    service(organizationFormData)
      .then((res) => {
        success("Organization saved.");
        dispatch({ type: HIDE_SPINNER });

        setOrganization(organization, false);

        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      })
      .finally(clearModal);
  };

  const addUserToOrganization = async (userData, callback) => {
    return OrganizationsService.postUserOrganization(userData).then((res) => {
      if (res.data.user?.email) {
        recoverPassword(res.data.user?.email);
      }

      getSingleOrganization(userData.organization_id);
      success("User added");
      if (typeof callback === "function") callback();
    });
  };

  const deleteOrganization = (organization_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    OrganizationsService.deleteOrganization(organization_id)
      .then(() => {
        success("Organization deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        setOrganization({ name: "Personal" });
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteUserOrganization = (organization_id, user_id) => {
    dispatch({ type: SHOW_SPINNER });
    return new Promise((resolve, reject) => {
      OrganizationsService.deleteUserOrganization(organization_id, user_id)
        .then(() => {
          success("User deleted from " + state.organization.name);
          dispatch({ type: HIDE_SPINNER });
          clearModal();
          getSingleOrganization(organization_id);
          resolve();
        })
        .catch((error) => {
          dispatch({ type: HIDE_SPINNER });
          alert(error);
          reject(error);
        });
    });
  };

  return (
    <OrganizationsContext.Provider
      value={{
        ...state,
        setInterceptor,
        setOrganization,
        getOrganizationUsers,
        getAdminOrganizations,
        getUserOrganizations,
        saveOrganization,
        deleteOrganization,
        createOrganization,
        getSingleOrganization,
        setPropertyOrganization,
        addUserToOrganization,
        getSingleUserOrganization,
        deleteUserOrganization,
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
};
