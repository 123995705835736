import React, { useContext } from "react";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { AuthContext } from "../../../context/AuthContext";
import useWindowSize from "../../../hooks/useWindowSize";
import getThumbnailUrl from "../../../utils/thumbnails";
import bunny from "../../../assets/bunny.png";
import { Link } from "@reach/router";

const OrganizationCard = ({ organization }) => {
  const { setOrganization } = useContext(OrganizationsContext);
  const { user_organization } = useContext(AuthContext);

  const isSmallDevice = useWindowSize(1200);

  const getThumbnail = () => {
    return organization.thumbnail != null
      ? getThumbnailUrl(organization.thumbnail)
      : bunny;
  };

  const renderOrganizationActions = () => {
    if (
      user_organization != null &&
      user_organization?.user_type?.name !== "Member"
    ) {
      return (
        <div>
          <div className="d-flex justify-content-end align-items-center">
            <Link
              to={`/organization/admin-panel`}
              onClick={() => setOrganization(organization)}
            >
              <button className="btn">
                <i className="fa fa-user"></i>
              </button>
            </Link>
            <Link
              to={`/organizations/organization-settings/${organization.organization_id}`}
            >
              <button className="btn">
                <i className="fa fa-cog"></i>
              </button>
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="col-sm-6 col-md-4 col-xl-3 mb-3">
      <div
        className="card mb-3 bg-light h-100 d-flex flex-column justify-content-between"
        style={{ padding: !isSmallDevice ? "1.5rem" : "1rem" }}
      >
        <img
          src={getThumbnail()}
          alt={`Thumbnail for Organization: ${organization.name}`}
          style={{
            maxHeight: "50%",
            minHeight: "50%",
            maxWidth: "100%",
            objectFit: "cover",
            borderRadius: 5,
          }}
        />
        <h4 className="text-capitalize mt-3 mb-0">{organization.name}</h4>
        <p className="text-capitalize p-0">{organization.tagline}</p>
        {renderOrganizationActions()}
      </div>
    </div>
  );
};

export default OrganizationCard;
