import React, { useContext, useEffect } from "react";
import TemplateCard from "./TemplateCard";
import { ModalContext } from "../../context/ModalContext";
import { TemplatesContext } from "../../context/TemplatesContext";
import TemplateInfo from "./TemplateInfo";
import { setupTooltips } from "../../utils";
import TemplateTagCard from "./TemplateTagCard";
import TemplateTypeCard from "./TemplateTypeCard";
import { navigate } from "@reach/router";
import useTranslations from "../../hooks/useTranslations";
import { ConversationsContext } from "../../context/ConversationsContext";
import { TranslationsContext } from "../../context/TranslationsContext";
import Pagination from "../global/Pagination";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import { AuthContext } from "../../context/AuthContext";
import useTemplateFilters from "../../hooks/templates/useTemplateFilters";

const TemplateList = ({
  tag,
  defaultSelected,
  type,
  size,
  title,
  hideEdit,
  avatar_id,
  hideCreate,
  handleApply,
  fetchTemplates,
  disableAddBtn,
  titleGradient,
  backBtn,
  handleBackBtn,
}) => {
  const { filters, setFilterValue, setManyFilterValues } = useTemplateFilters();

  const {
    page,
    query,
    sortBy,
    showFavorites,
    currentTag,
    currentType,
    templates_tab,
  } = filters;

  const translations = useTranslations();
  const { user } = useContext(AuthContext);
  const { organization } = useContext(OrganizationsContext);
  const { getConversations } = useContext(ConversationsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { tags, total, types, spinner, templates, getAllTags, getAllTypes } =
    useContext(TemplatesContext);
  const { lang } = useContext(TranslationsContext);

  useEffect(() => {
    getConversations();
    handleCallback();

    if (tag && tag != null) {
      setFilterValue("currentTag", tag);
    }

    if (type && type != null) {
      setFilterValue("currentType", type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!spinner && user) {
      handleCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    query,
    templates_tab,
    showFavorites,
    sortBy,
    user,
    organization,
    lang,
    currentTag,
    currentType,
  ]);

  useEffect(() => {
    // handleCallback();
    if (templates_tab.length > 0) {
      setFilterValue("page", 1);
      // setTemplates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates_tab]);

  const handleSetType = (type) => {
    setManyFilterValues([
      { key: "currentTag", value: null },
      { key: "currentType", value: type },
      { key: "templates_tab", value: "" },
    ]);
  };

  const handleSetTag = (tag) => {
    setManyFilterValues([
      { key: "currentType", value: null },
      { key: "currentTag", value: tag },
      { key: "templates_tab", value: "" },
    ]);
  };

  useEffect(() => {
    setupTooltips();
  }, [templates]);

  useEffect(() => {
    if (templates_tab === "") {
      setManyFilterValues([
        { key: "sortBy", value: "" },
        { key: "page", value: 1 },
      ]);
    } else {
      setFilterValue("page", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates_tab]);

  const getSortBy = () => {
    if (sortBy.includes("tag") && templates_tab !== "tags") {
      let current = `${templates_tab}_${String(sortBy.split("_")[1])}`;
      setFilterValue("sortBy", current);
      return current;
    } else if (sortBy.includes("type") && templates_tab !== "type") {
      let current = `${templates_tab}_${String(sortBy.split("_")[1])}`;
      setFilterValue("sortBy", current);
      return current;
    }
    return sortBy;
  };

  const handleCallback = () => {
    if (!spinner) {
      const current = getSortBy();

      const params = { query, page, language: lang, sortBy: current };

      if (organization && organization !== null) {
        if (organization.organization_id !== null) {
          params.organization_id = organization.organization_id;
        }
      }

      if (templates_tab === "tags") {
        getAllTags(params);
      }

      if (templates_tab === "type") {
        getAllTypes(params);
      }

      if (templates_tab === "") {
        fetchTemplates({
          tag: currentTag,
          type: currentType,
          favorites: showFavorites,
          ...params,
        });
      }
    }
  };

  const handleInfo = () => {
    if (size !== "lg") {
      clearModal();
      setTimeout(() => {
        modalComponent("About Templates", <TemplateInfo />);
      }, 500);
    } else {
      modalComponent("About Templates", <TemplateInfo />);
    }
  };

  const handleCreateTemplate = () => {
    navigate("/templates/create-template");
  };

  const handleBack = () => {
    if (currentType) {
      setManyFilterValues([
        { key: "templates_tab", value: "type" },
        { key: "currentType", value: null },
      ]);
    } else {
      setManyFilterValues([
        { key: "templates_tab", value: "tags" },
        { key: "currentTag", value: null },
      ]);
    }
  };

  const renderButtons = () => {
    const handleChangeTab = (tabKey) => {
      return () => {
        setManyFilterValues([
          { key: "templates_tab", value: tabKey },
          { key: "page", value: 1 },
        ]);
      };
    };
    if (currentType || currentTag) {
      return (
        <button onClick={handleBack} className="btn btn-outline-primary ms-3">
          <i className="fa fa-chevron-left me-2"></i>{" "}
          {translations.templates.back}
        </button>
      );
    } else {
      return (
        <div
          className="btn-group ms-2 d-inline-block border br-25 mt-2"
          role="group"
          style={{
            minWidth: 180,
          }}
        >
          <button
            type="button"
            onClick={handleChangeTab("type")}
            className={`btn btn-sm ${
              templates_tab === "type" ? "btn-primary" : ""
            }`}
          >
            {translations.templates.type}
          </button>
          <button
            type="button"
            onClick={handleChangeTab("tags")}
            className={`btn btn-sm ${
              templates_tab === "tags" ? "btn-primary" : ""
            }`}
          >
            {translations.templates.tag}
          </button>
          <button
            type="button"
            onClick={handleChangeTab("")}
            className={`btn btn-sm ${
              templates_tab === "" ? "btn-primary" : ""
            }`}
          >
            {translations.templates.all}
          </button>
        </div>
      );
    }
  };

  const renderTemplates = () => {
    if (Array.isArray(templates) && templates_tab === "") {
      let templatesRender = [...templates];
      if (
        organization &&
        organization !== null &&
        organization.organization_id !== null
      ) {
        templatesRender = templatesRender.filter(
          ({ organization_id }) =>
            organization.organization_id === organization_id
        );
      }
      if (templatesRender.length === 0) {
        return <p>{translations.templates.empty}</p>;
      }
      return templatesRender.map((template) => (
        <div
          key={template.template_id}
          className={`col-12 ps-0 ${
            size === "lg" ? "col-xxl-4 col-xl-6 col-md-6 col-sm-6" : ""
          } `}
          style={{
            height: "max-content",
          }}
        >
          <TemplateCard
            handleCallback={handleCallback}
            handleApply={handleApply}
            avatar_id={avatar_id}
            hideEdit={hideEdit}
            template={template}
            size={size}
          />
        </div>
      ));
    }
    if (Array.isArray(tags) && templates_tab === "tags") {
      let tagsRender = [...tags];
      if (
        organization &&
        organization !== null &&
        organization.organization_id !== null
      ) {
        tagsRender = tagsRender.filter(
          ({ organization_id }) =>
            organization.organization_id === organization_id
        );
      }
      return tagsRender?.map((tag) => (
        <TemplateTagCard
          tag={tag}
          size={size}
          key={tag.tag_id}
          setCurrentTag={handleSetTag}
        />
      ));
    }
    if (Array.isArray(types) && templates_tab === "type") {
      let typesRender = [...types];
      if (
        organization &&
        organization !== null &&
        organization.organization_id !== null
      ) {
        typesRender = typesRender.filter(
          ({ organization_id }) =>
            organization.organization_id === organization_id
        );
      }
      return typesRender.map(({ type, templates }) => (
        <TemplateTypeCard
          key={type}
          type={type}
          size={size}
          templates={templates}
          setCurrentType={handleSetType}
        />
      ));
    }
    if (spinner) return <div className="spinner-border"></div>;
  };

  const renderEmptyState = () => {
    if (templates?.length <= 0 && templates_tab === "") {
      return <p>There aren't any templates</p>;
    }

    if (tags?.length <= 0 && templates_tab === "tags") {
      return <p>There aren't any Tags</p>;
    }

    if (types?.length <= 0 && templates_tab === "type") {
      return <p>There aren't any Types</p>;
    }
  };

  const renderAddBtn = () => {
    if (!disableAddBtn) {
      return (
        <div>
          {!hideCreate && (
            <button
              className="btn btn-outline-primary w-100"
              onClick={handleCreateTemplate}
            >
              <i className="fa fa-plus me-2"></i>
              {translations.templates.create}
            </button>
          )}
        </div>
      );
    }
  };

  const renderPagination = () => {
    if ((total && total !== null) || page > 1) {
      return (
        <div className="py-3">
          <Pagination
            size={"sm"}
            itemsPerPage={24}
            totalItems={total}
            currentPage={page}
            onPageChange={(currentPage) => {
              setFilterValue("page", currentPage);
            }}
          />
        </div>
      );
    }
  };

  return (
    <div
      id="templates"
      className="overflow-hidden position-relative
       h-100 d-flex flex-column flex-nowrap"
    >
      <section className="" style={{ height: "max-content" }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-5 ps-0">
            <div>
              <button
                className={`btn btn-sm btn-accent mb-1 ${
                  backBtn ? "" : "d-none"
                }`}
                type="button"
                onClick={handleBackBtn}
              >
                <i className="fa fa-arrow-left me-2" />
                Conversation
              </button>
            </div>

            {size === "lg" ? (
              <h1
                className={`mb-0 text-capitalize ${
                  titleGradient ? "text-gradient" : ""
                }  d-inline-block`}
              >
                {title}
              </h1>
            ) : (
              <h1
                className={`mb-0 h3 bold ${
                  titleGradient ? "text-gradient" : ""
                } d-inline-block`}
              >
                {title}
              </h1>
            )}
          </div>

          <div className="col-12 col-md-7 pe-0 text-end">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translations.templates.favorites}
              className={`btn btn-round btn-sm mt-2 ${
                showFavorites > 0 ? "btn-warning text-dark" : "border"
              } me-2`}
              onClick={() => setFilterValue("showFavorites", !showFavorites)}
            >
              <i className="fa fa-star"></i>
            </button>

            <button
              onClick={handleInfo}
              className="btn btn-round border btn-sm mt-2"
            >
              <i className="fa fa-info-circle"></i>
            </button>
            {renderButtons()}
          </div>
        </div>

        <div className="row align-items-center my-3">
          <div
            className={` col-12 mb-2 mb-md-0 px-0 pe-md-2 col-md-${
              size === "lg" ? "7" : "12"
            }`}
          >
            <input
              type="text"
              value={query}
              className="form-control "
              placeholder={translations.templates.search}
              onChange={(e) => setFilterValue("query", e.target.value)}
            />
          </div>

          <div className={`col-12 px-0 col-md-${size === "lg" ? "5" : "12"}`}>
            <div className={`row ${disableAddBtn ? "mt-2" : ""}`}>
              <div className="col-6 text-end px-0 mb-2 mb-md-0">
                <select
                  value={sortBy}
                  onChange={(e) => setFilterValue("sortBy", e.target.value)}
                  className="form-control d-inline-block"
                >
                  <option value="createdAt_desc">Last Created</option>
                  <option value="updatedAt_desc">Last Used</option>
                  <option
                    value={`${
                      templates_tab === "" ? "name" : templates_tab
                    }_asc`}
                  >
                    A-Z
                  </option>
                  <option
                    value={`${
                      templates_tab === "" ? "name" : templates_tab
                    }_desc`}
                  >
                    Z-A
                  </option>
                </select>
              </div>
              <div className="col-6 pe-0">{renderAddBtn()}</div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`row align-items-start justify-content-start`}
        style={{ overflowY: "auto" }}
      >
        {renderEmptyState()}
        {renderTemplates()}
      </div>
      {renderPagination()}
    </div>
  );
};

export default TemplateList;
