import Select from "react-select";
import React, { useContext, useState, useEffect } from "react";
import ShareToPlatformCard from "./ShareToPlatformCard";
import { AuthContext } from "../../../context/AuthContext";
import useTranslations from "../../../hooks/useTranslations";
import { AvatarsContext } from "../../../context/AvatarsContext";
import { MessagesContext } from "../../../context/MessagesContext";
import useFilesConverter from "../../../hooks/global/useFilesConverter";

const ShareMessageForm = ({ message, handleCancel }) => {
  const { shareMessageContent } = useContext(MessagesContext);
  const { linkedPlatforms } = useContext(AuthContext);
  const { avatars, getAvatars, saveAvatarEditableFile } =
    useContext(AvatarsContext);

  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const translations = useTranslations();

  const { export2Doc, export2pdf } = useFilesConverter();

  useEffect(() => {
    getAvatars();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = () => {
    if (selectedPlatform) {
      shareMessageContent({ ...message, platform: selectedPlatform.name });
    }
  };

  const renderLinkedPlatforms = () => {
    if (!linkedPlatforms || selectedPlatform) return null;
    return linkedPlatforms.map((platform) => (
      <ShareToPlatformCard
        key={platform.name}
        platform={platform}
        setPlatform={setSelectedPlatform}
      />
    ));
  };

  const handleAddAvatarFile = async () => {
    if (selectedAvatar) {
      const assistant_id = selectedAvatar.assistant_id;
      const avatar_id = selectedAvatar.avatar_id;
      const text = message.content;
      const fileName = `message-${message.message_id}`

      await saveAvatarEditableFile({
        text,
        fileName,
        assistant_id,
        avatar_id,
      });
    }
  };

  const renderShareOptions = () => {
    if (selectedPlatform) return null;
    if (Array.isArray(avatars)) {
      const options = avatars.map((current) => {
        return {
          label: current.name,
          value: current.avatar_id,
        };
      });

      return (
        <>
          {renderLinkedPlatforms()}
          <div
            className="card position-relative bg-light mb-3 my-3"
            style={{ height: "100px" }}
          >
            <div className="card-body pb-2 position-relative d-flex flex-column align-items-start">
              <h3 className="m-0 p-0">Word</h3>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-view"
              onClick={() => export2Doc("document", message.content)}
            >
              <i className="fa fa-share"></i>
            </button>
          </div>
          <div
            className="card position-relative bg-light mb-3 my-3"
            style={{ height: "100px" }}
          >
            <div className="card-body pb-2 position-relative d-flex flex-column align-items-start">
              <h3 className="m-0 p-0">PDF</h3>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-view"
              onClick={() => export2pdf(Buffer.from(message.content))}
            >
              <i className="fa fa-share"></i>
            </button>
          </div>
          <div
            className="card position-relative bg-light mb-3 my-3"
            style={{ height: "100px" }}
          >
            <div className="card-body pb-2 position-relative d-flex flex-column align-items-start">
              <h3 className="m-0 p-0">{translations.avatars.add_context}</h3>
              <Select
                className="basic-single w-75 mt-2 px-0"
                classNamePrefix="select an avatar"
                isSearchable
                onChange={(newValue) => {
                  const selected = avatars.find((obj) => {
                    return obj.avatar_id === newValue.value;
                  });

                  console.log(selected);

                  setSelectedAvatar(selected);
                }}
                name="color"
                options={options}
              />
            </div>

            <button
              type="button"
              className="btn btn-primary btn-view"
              onClick={handleAddAvatarFile}
            >
              <i className="fa fa-share"></i>
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <div>
      {selectedPlatform ? (
        <div className="mb-3">
          <p>
            Are you sure you want to share this content to{" "}
            <b>{selectedPlatform.title}</b>?
          </p>
          <div className="my-4 card bg-light p-3">{message?.content}</div>
        </div>
      ) : (
        <div className="mb-3">
          <p>Share to which platforms?</p>
          {renderShareOptions()}
        </div>
      )}

      <div className="row">
        {selectedPlatform ? (
          <div className="col-6">
            <button
              onClick={() => setSelectedPlatform(null)}
              className="btn w-100 text-muted"
            >
              Go Back
            </button>
          </div>
        ) : (
          <div className="col-6">
            <button onClick={handleCancel} className="btn w-100 text-muted">
              Cancel
            </button>
          </div>
        )}
        <div className="col-6">
          {selectedPlatform ? (
            <button onClick={handleShare} className="btn w-100 btn-primary">
              <i className="fa fa-share me-2"></i> Share
            </button>
          ) : (
            <a
              href="https://zapier.com/find-apps/a"
              target="_blank"
              rel="noopener noreferrer"
              className="btn w-100 btn-primary"
            >
              Other Platforms
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareMessageForm;
