import io from "socket.io-client";
import { BASE_URL } from "../utils";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ModalContext } from "../context/ModalContext";
import { OutputsContext } from "../context/OutputsContext";
import { MessagesContext } from "../context/MessagesContext";
import { SuperFetchContext } from "../context/SuperFetchContext";
import { DocumentsContext } from "../context/DocumentsContext";
import { ConversationsContext } from "../context/ConversationsContext";
import useTranslations from "./useTranslations";

const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const { user, getCurrentUser } = useContext(AuthContext);
  const { alert } = useContext(ModalContext);
  
  const {
    setEnhancing,
    appendMessage,
    setGenerating,
    setRuningThread,
    appendEnhancedToken,
    appendThreadMessage,
    setPropertyMessagePlural,
    setThreadMessages,
  } = useContext(MessagesContext);

  const translations = useTranslations();

  const { setLoading, setOutputs, setFeedbackForm, setEnhancedPrompt } =
    useContext(SuperFetchContext);

  const { prependOutput } = useContext(OutputsContext);
  const { getSingleConversation } = useContext(ConversationsContext);

  const { setAvatarDocumentOutput, setContextAvatarOutput } = useContext(DocumentsContext);


  const setupSocket = () => {
    const currentSocket = io(BASE_URL, {
      transports: ["websocket"],
    });
    currentSocket.on("connect", () => handleConnection(currentSocket));

    currentSocket.on("output", handleOutput);

    currentSocket.on("message", handleMessage);

    currentSocket.on("message_stream", handleMessageStream);

    currentSocket.on("message_stream_end", handleFinishEnhanced);

    currentSocket.on("message_stream_enhance", handleStreamEnhanced);

    currentSocket.on("message_stream_enhance_end", handleFinishEnhanced);

    currentSocket.on("thread_messages", handleThreadMessages);

    currentSocket.on("super_fetch_outputs", handleSuperFetchOutputs);

    currentSocket.on("document_output", handleDocumentOutput);

    currentSocket.on("document_avatar", handleContextAvatarOutput);

    currentSocket.on("error", handleError);

    currentSocket.on("openai-error", handleOpenaiError);

    setSocket(currentSocket);
  };

  const handleDocumentOutput = (output) => {
    setAvatarDocumentOutput(output); 
  }

  const handleContextAvatarOutput = (thread_messages) => {
    const avatarOutput = thread_messages[thread_messages.length -1];
    setContextAvatarOutput(avatarOutput);
    setRuningThread(false);
  }

  const handleMessage = (data) => {
    appendMessage(data);
    setGenerating(false);
  };

  const handleThreadMessages = (messages) => {
    if(messages.saveOnConversation && messages.conversation_id) {
      getSingleConversation(messages.conversation_id);
    } else {
      if (messages.message) {
        appendThreadMessage(messages.message)
      } else {
        setThreadMessages(messages.threadMessages || messages);
      }
    }
    setRuningThread(false);
    setGenerating(false);
  };

  const handleSuperFetchOutputs = (data) => {
    const { outputs, enhancePrompt } = data;
    if (enhancePrompt) {
      setEnhancedPrompt(outputs[0]);
    } else {
      setOutputs(outputs);
      setFeedbackForm(true);
    }
    setLoading(false);
  };

  const handleMessageStream = (data) => {
    if (
      data.content &&
      data.content !== null &&
      !String(data.content) !== "undefined" &&
      !String(data.content).includes("undefined")
    ) {
      setPropertyMessagePlural(data.message_id, "content", data.content);
    }
  };

  const handleStreamEnhanced = (data) => {
    if (
      data.content &&
      data.content !== null &&
      !String(data.content) !== "undefined" &&
      !String(data.content).includes("undefined")
    ) {
      appendEnhancedToken(data.content);
    }
  };

  const handleOutput = (data) => {
    prependOutput(data);
  };

  const handleConnection = (socket) => {
    const room = `user-${user.user_id}`;
    socket.emit("join_room", room);
  };

  const handleError = (data) => {
    alert(data);
  };


  const handleOpenaiError = (data) => {
    if(data.status === 500 || data.status === '500'){
      alert(translations.openai.error);
    } else {
      const errorMessage = `OpenAi Error: ${data.error.message}`
      alert(errorMessage);
    }
    setGenerating(false);
    setRuningThread(false);
    setEnhancing(false);
  };

  const handleFinishEnhanced = () => {
    setGenerating(false);
    setEnhancing(false);
    getCurrentUser();
  };



  return [socket, setupSocket];
};

export default useSocket;
