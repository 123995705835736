import React, { useContext, useEffect } from "react";
import OrganizationAdminTable from "../../../components/organization/organizations/OrganizationAdminTable";
import OrganizationPanelHeader from "../../../components/organization/organizations/OrganizationPanelHeader";
import OrganizationTableRow from "../../../components/organization/organizations/OrganizationTableRow";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import useTranslations from "../../../hooks/useTranslations";
import useOrganization from "../../../hooks/useOrganization";
import { formatMonto } from "../../../utils";

const userTableCols = [
  {
    name: "#ID",
  },
  {
    name: "Name",
  },
  {
    name: "Email",
  },
  {
    name: "Role",
  },
  {
    name: "Total Words",
  },
  {
    name: "Last used",
  },
  {
    name: "LTV ($)"
  }
];

const OrganizationPanel = () => {

  const { users, getOrganizationUsers } = useContext(OrganizationsContext);

  const { organization } = useOrganization();

  const { formatDate } = useTranslations();

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = ({ query, start_date, end_date } = {}) => {
    getOrganizationUsers(organization.organization_id, {
      query,
      start_date,
      end_date,
    });
  };

  const renderOrganizationUsers = () => {
    return users?.map((obj) => {
      obj.ltv = typeof obj.ltv !== "string" ? `$${formatMonto(obj.ltv)}` : obj.ltv;
      obj.last_used =
        obj.last_used !== null ? formatDate(obj.last_used) : "Not used yet";
      return (
        <OrganizationTableRow
          user={obj}
          key={obj.user_id}
          link={`users/${obj.user?.user_id}`}
          extraFields={[{ key: "word_count" }, { key: "last_used" }, { key: "ltv" }]}
        />
      );
    });
  };

  return (
    <div className="container-fluid bg-white p-3 rounded-3 h-100 d-flex flex-column">
      <OrganizationPanelHeader
        showSearch
        showDateFilters
        handleChange={fetchUsers}
      />
      <div
        className="container-fluid px-0 mt-3"
        style={{ flex: 1, overflowY: "auto" }}
      >
        <OrganizationAdminTable tableColumns={userTableCols}>
          {renderOrganizationUsers()}
        </OrganizationAdminTable>
      </div>
    </div>
  );
};

export default OrganizationPanel;
