import { HIDE_SPINNER, SHOW_SPINNER, USERS_RECEIVED } from "../types";
import {
  SET_INTERCEPTOR,
  SET_ORGANIZATION,
  CREATE_ORGANIZATION,
  SET_USER_ORGANIZATION,
  ORGANIZATIONS_RECEIVED,
  SET_ORGANIZATION_TO_EDIT,
  SET_PROPERTY_ORGANIZATION,
  ADMIN_ORGANIZATIONS_RECEIVED,
} from "../types/Organizations";

const schema = {};

const OrganizationsReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false }
    case ADMIN_ORGANIZATIONS_RECEIVED:
      return { ...state, adminAllOrganizations: payload };
    case ORGANIZATIONS_RECEIVED:
      return { ...state, organizations: payload };
    case SET_ORGANIZATION:
      return { ...state, organization: payload };
    case SET_ORGANIZATION_TO_EDIT:
      return { ...state, organizationToEdit: payload };
    case SET_USER_ORGANIZATION:
      return { ...state, user_organization: payload };
    case CREATE_ORGANIZATION:
      return { ...state, organization: schema };
    case SET_PROPERTY_ORGANIZATION: {
      const { key, value } = payload;
      const Organization = { ...state.organization };
      Organization[key] = value;
      return { ...state, Organization };
    }
    case USERS_RECEIVED:
      return { ...state, users: payload };
    case SET_INTERCEPTOR:
      return { ...state, organizationInterceptor: payload };
    default:
      return { ...state };
  }
};

export default OrganizationsReducer;
