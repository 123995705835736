import React, { useEffect, useContext } from "react";
import { AvatarsContext } from "../context/AvatarsContext";
import AvatarForm from "../components/avatars/AvatarForm";
import AvatarList from "../components/avatars/AvatarList";
import useTranslations from "../hooks/useTranslations";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import { AuthContext } from "../context/AuthContext";
import Upgrade from "./Upgrade";
import useOrganization from "../hooks/useOrganization";

const Avatars = () => {
  const {
    avatars,
    setAvatar,
    getAvatars,
    clearAvatars,
    createAvatar,
    getPublicAvatars,
  } = useContext(AvatarsContext);
  
  const translations = useTranslations();
  const { organization } = useOrganization();

  const { user } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);
  const { modalComponent, clearModal } = useContext(ModalContext);

  useEffect(() => {
    fetchAvatars();
    setSelected("Avatars");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, organization]);

  const fetchAvatars = (query) => {
    clearAvatars();
    setAvatar(null);
    if (window.location.pathname.includes("community")) {
      getPublicAvatars(query);
    } else {
      getAvatars(query);
    }
  };

  const handleCreate = () => {
    createAvatar();
    modalComponent(`${translations.general.create} Avatar`, <AvatarForm handleCancel={clearModal} />);
  };

  const renderContent = () => {
    if (user.has_access) {
      const isCommunity = window.location.pathname.includes("community");
      const title = isCommunity
        ? translations.avatars.title
        : translations.user_avatars.title;
      return (
        <div>
          <AvatarList
            hideButton={!isCommunity}
            handleCreate={handleCreate}
            fetchAvatars={fetchAvatars}
            avatars={avatars}
            title={title}
          />
        </div>
      );
    }
    return <Upgrade>{renderUpgradeContent()}</Upgrade>;
  };

  const renderUpgradeContent = () => {
    return (
      <div>
        <p>
          {translations.avatars.upgrade}
        </p>
      </div>
    );
  };

  return <div className="container-fluid px-0 h-100">{renderContent()}</div>;
};

export default Avatars;
