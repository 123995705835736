import React, { useContext } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { getValue } from "../../utils";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import useTranslations from "../../hooks/useTranslations";
import useOrganization from "../../hooks/useOrganization";

const AvatarForm = ({ handleCancel }) => {
  const translations = useTranslations();

  const { organization } = useOrganization();

  const { user } = useContext(AuthContext);
  const { alert } = useContext(ModalContext);
  const { avatar, spinner, saveAvatar, setPropertyAvatar } =
    useContext(AvatarsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (avatar.name === "") {
      return alert("You must add a name to your avatar.");
    }
    avatar.user_id = user.user_id;
    if(organization?.organization_id !== null) {
      avatar.organization_id = organization.organization_id;
    }
    saveAvatar(avatar);
  };

  return (
    <form onSubmit={handleSubmit} className="container px-0">
      <label>{translations.general.name}</label>
      <input
        type="text"
        value={getValue(avatar, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyAvatar("name", e.target.value)}
      />
      <label>{translations.general.description}</label>
      <input
        type="text"
        value={getValue(avatar, "description")}
        className="form-control mb-3"
        onChange={(e) => setPropertyAvatar("description", e.target.value)}
      />
      <div className="row mt-3">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-link w-100 text-muted"
          >
            {translations.general.cancel}
          </button>
        </div>
        <div className="col-6">
          <button
            type="submit"
            disabled={spinner}
            className="btn btn-primary w-100"
          >
            {spinner ? <div className="spinner-border"></div> : translations.general.save}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AvatarForm;
