import { useContext, useEffect, useState } from "react";
import { OrganizationsContext } from "../context/OrganizationsContext";
import { AuthContext } from "../context/AuthContext";
// import { setupColor } from "../utils";
import api from "../services/api";

const defaultOrganization = { name: "Personal", organization_id: null };

const useOrganization = () => {
  const [fetchingUser, setFetchingUser] = useState(false);
  const {
    spinner,
    organization,
    organizations,
    setInterceptor,
    setOrganization,
    getUserOrganizations,
    organizationInterceptor,
  } = useContext(OrganizationsContext);
  const { user, user_organization, getCurrentUserOrganization } =
    useContext(AuthContext);

  const organization_id = organization?.organization_id;

  const defaultInterceptor = (config) => config;

  useEffect(() => {
    if (user && user !== null && !spinner && !Array.isArray(organizations)) {
      getUserOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (
      !fetchingUser &&
      organization?.organization_id !== null &&
      user_organization?.organization_id !== organization?.organization_id
    ) {
      setFetchingUser(true);
      getCurrentUserOrganization(user?.user_id, organization?.organization_id);
    }
    setupInterceptors(organization?.organization_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    handleSetOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  useEffect(() => {
    if (
      user_organization?.organization_id === organization?.organization_id &&
      organization?.organization_id !== null
    ) {
      setFetchingUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_organization]);

  useEffect(() => {
    if (
      organizationInterceptor !== undefined &&
      organizationInterceptor !== null
    ) {
      for (let i = 0; i < organizationInterceptor; i++) {
        api.interceptors.request.eject(i);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationInterceptor]);

  const setOrganizationBySlug = () => {
    let organizationSet = false;
    if (Array.isArray(organizations)) {
      const { pathname, hostname } = window.location;
      for (const organizationData of organizations) {
        if (organizationData !== null) {
          if (
            pathname.includes(organizationData?.slug) ||
            hostname === organizationData?.domain
          ) {
            localStorage.setItem(
              "organization_id",
              organizationData.organization_id
            );
            selectOrganization(organizationData.organization_id);
            organizationSet = true;
          }
        }
      }
    }
    return organizationSet;
  };

  const setOrganizationByLocalStorage = () => {
    const localOrganization = window.localStorage.getItem("organization_id");
    let organizationSet = false;

    if (
      localOrganization &&
      localOrganization !== null &&
      !isNaN(parseInt(localOrganization))
    ) {
      selectOrganization(localOrganization);
      organizationSet = true;
    }

    return organizationSet;
  };

  const setOrganizationByCustomer = () => {
    let organizationSet = false;
    if (
      user?.default_organization &&
      user?.default_organization !== null &&
      user?.default_organization !== undefined
    ) {
      selectOrganization(user.default_organization.organization_id);
      organizationSet = true;
    }

    return organizationSet;
  };

  const setDefaultOrganization = () => {
    window.localStorage.setItem("organization_id", null);
    selectOrganization(null);
  };

  const handleSetOrganization = () => {
    let alreadySet = setOrganizationBySlug();
    if (!alreadySet) alreadySet = setOrganizationByLocalStorage();
    if (!alreadySet) alreadySet = setOrganizationByCustomer();
    if (!alreadySet) setDefaultOrganization();
  };

  const selectOrganization = (organization_id) => {
    if (organization_id === null) {
      return setOrganization(defaultOrganization);
    }
    
    const selected = organizations?.find(
      (organization) =>
        String(organization.organization_id) === String(organization_id)
    );

    if (selected && selected !== null) {
      if (
        organization !== null &&
        selected.organization_id !== organization.organization_id
      ) {
        window.localStorage.setItem(
          "organization_id",
          selected.organization_id
        );
        // return window.location.reload();
      }
      setOrganization(selected);
    }
  };

  const setupInterceptors = (organization_id) => {

    if (organization_id === null || organization_id === undefined) {
      if (organizationInterceptor !== null) {
        api.interceptors.request.eject(organizationInterceptor);
      }
      api.interceptors.request.use(defaultInterceptor);
      return setInterceptor(null);
    }

    const newInterceptor = function (config) {
      // Do something before request is sent
      if (
        (config.method === "get" && !config.url.includes("organization_id")) ||
        (config.method === "delete" && !config.url.includes("organization_id"))
      ) {
        if (config.url.includes("?")) {
          config.url = config.url + `&organization_id=${organization_id}`;
        } else {
          config.url = config.url + `?organization_id=${organization_id}`;
        }
      }
      return config;
    };

    const result = api.interceptors.request.use(newInterceptor);

    setInterceptor(result);
  };

  const role = user_organization?.user_type?.name;

  return {
    role,
    organization,
    organizations,
    setOrganization,
    organization_id,
    selectOrganization,
    getUserOrganizations,
    handleSetOrganization,
    setDefaultOrganization,
    organizationInterceptor,
  };
};

export default useOrganization;
